import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  Sentry.init({
    environment: environment.production ? "production" : "development",
    dsn: environment.dsn,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [ environment.baseUrl ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// TODO Refactor login page according with design.
// TODO Refactor left side panel (move user info from upper right corner to left down corner of left side panel)
