import { Pipe, PipeTransform } from '@angular/core';
import { Booking, Offer } from 'common';

@Pipe({
  name: 'searchDashboard'
})
export class SearchDashboardPipe implements PipeTransform {

  transform(bookingsOrOffers: Array<Booking | Offer>, search: string, filters?: any): Array<any> {
    let newArr = bookingsOrOffers;
    if (search || search.length >= 3) {
      const word = search.toLowerCase();
      newArr = newArr.filter(booking => {
        if (booking.id?.toLowerCase().includes(word)) return booking;
        if ((booking as Booking).frpId?.toLowerCase().includes(word)) return booking;
        for (const item of (booking as Booking).bookingLegs || booking.bids) {
          if (item.aircraftInfo?.typeName?.toLowerCase().includes(word)) return booking;
          if (item.aircraftInfo?.tailNumber?.toLowerCase().includes(word)) return booking;
          if (item.from?.city?.toLowerCase().includes(word)) return booking;
          if (item.from?.countryName?.toLowerCase().includes(word)) return booking;
          if (item.from?.icao?.toLowerCase().includes(word)) return booking;
          if (item.from?.iata?.toLowerCase().includes(word)) return booking;
          if (item.from?.name?.toLowerCase().includes(word)) return booking;
          if (item.to?.city?.toLowerCase().includes(word)) return booking;
          if (item.to?.countryName?.toLowerCase().includes(word)) return booking;
          if (item.to?.icao?.toLowerCase().includes(word)) return booking;
          if (item.to?.iata?.toLowerCase().includes(word)) return booking;
          if (item.to?.name?.toLowerCase().includes(word)) return booking;
          if (item.refNumber?.toLowerCase().includes(word)) return booking;
        }
        return false;
      });
    }
    if (filters) {
      if (filters.sortBy === 'Flight date') {
        newArr = newArr.sort((a, b) => {
          if (filters.sortDirection === 'asc') {
            return a.bids[0].takeoffDate > b.bids[0].takeoffDate ? 1 : -1;
          } else return b.bids[0].takeoffDate > a.bids[0].takeoffDate ? 1 : -1;
        });
      } else {
        newArr = newArr.sort((a, b) => {
          if (filters.sortDirection === 'asc') {
            return a.created > b.created ? 1 : -1;
          } else return b.created > a.created ? 1 : -1;
        });
      }
    }
    return newArr;
  }
}
