import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MaintenanceService, UserService } from 'common';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Guest  {
  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.afAuth.user.pipe(
      tap(user => {
        if (user) {
          delete this.userService.pageAfterAuth;
          this.router.navigate([ '/company' ]);
        }
      }),
      map(user => !user),
      catchError((error: HttpErrorResponse): Observable<boolean> => {
        return of(false);
      })
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class NotVerified  {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.afAuth.user.pipe(
      tap(user => {
        if (!user) this.router.navigate([ '/register' ]);
        if (!!user && user.emailVerified) this.router.navigate([ '/company' ]);
      }),
      map(user => !!user && !user.emailVerified),
      catchError((error: HttpErrorResponse): Observable<boolean> => {
        return of(false);
      })
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class Authenticated  {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.userService.getUserInfo()
      .pipe(
        tap(user => {
          if (user.operatorAccountOwner?.active || user.operatorAccountAdmin?.active) this.router.navigate([ '/bookings' ]);
          else if (!!user.operatorAccountOwner || !!user.operatorAccountAdmin) this.router.navigate([ '/account' ]);
        }),
        map(user => !user.operatorAccountOwner && !user.operatorAccountAdmin),
        catchError((error: HttpErrorResponse): Observable<boolean> => {
          this.router.navigate([ '/register' ]);
          return of(false);
        })
      );
  }
}
@Injectable({
  providedIn: 'root'
})
export class Verified  {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private userService: UserService,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.user.pipe(
      tap(user => {
        if (user) {
          delete this.userService.pageAfterAuth;
          if (!user.emailVerified) this.router.navigate([ '/verification' ]);
        } else {
          this.userService.pageAfterAuth = {
            url: state.url.split('?')[0],
            params: state.root.queryParams
          };
          this.router.navigate([ '/' ]);
        }
      }),
      map(user => !!user && !!user.emailVerified),
      catchError((error: HttpErrorResponse): Observable<boolean> => {
        return of(false);
      })
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class Operator  {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.userService.getUserInfo()
      .pipe(
        tap(user => {
          if (!user.operatorAccountOwner && !user.operatorAccountAdmin) this.router.navigate([ '/company' ]);
        }),
        map(user => !!user.operatorAccountOwner || !!user.operatorAccountAdmin),
        catchError((error: HttpErrorResponse): Observable<boolean> => {
          this.router.navigate([ '/' ]);
          return of(false);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class OperatorActive  {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.userService.getUserInfo()
      .pipe(
        tap(user => {
          if ((!!user.operatorAccountOwner && !user.operatorAccountOwner?.active) || (!!user.operatorAccountAdmin && !user.operatorAccountAdmin.active)) this.router.navigate([ '/account' ]);
          else if (!user.operatorAccountOwner && !user.operatorAccountAdmin) this.router.navigate([ '/company' ]);
        }),
        map(user => user.operatorAccountOwner?.active || user.operatorAccountAdmin?.active),
        catchError((error: HttpErrorResponse): Observable<boolean> => {
          this.router.navigate([ '/' ]);
          return of(false);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceOn  {
  constructor(
    private maintenanceSrv: MaintenanceService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.maintenanceSrv.watchMaintenance()
      .pipe(
        tap(maintenance => {
          if (maintenance.status) {
            this.router.navigate([ '/maintenance' ]);
          }
        }),
        map(maintenance => !maintenance.status),
        catchError((error: HttpErrorResponse): Observable<boolean> => {
          return of(false);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class MaintenanceOff  {
  constructor(
    private maintenanceSrv: MaintenanceService,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.maintenanceSrv.watchMaintenance()
      .pipe(
        tap(maintenance => {
          if (!maintenance.status) {
            this.router.navigate([ '/' ]);
          }
        }),
        map(maintenance => !!maintenance.status),
        catchError((error: HttpErrorResponse): Observable<boolean> => {
          return of(false);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedFirebase  {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this.afAuth.user.pipe(
      tap(user => {
        if (!user) this.router.navigate([ '/register' ]);
      }),
      map(user => !!user),
      catchError((error: HttpErrorResponse): Observable<boolean> => {
        return of(false);
      })
    );
  }
}
