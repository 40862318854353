import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {
  private sidenav!: MatSidenav;
  curTitle = new BehaviorSubject('OPES JET Admin');

  getTitleObject(): BehaviorSubject<string> {
    return this.curTitle;
  }

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  public open(): Promise<MatDrawerToggleResult> {
    return this.sidenav.open();
  }


  public close(): Promise<MatDrawerToggleResult> {
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

}
