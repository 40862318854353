<mat-sidenav-container *ngIf="operatorAccountInfo" class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isTablet$ | async) ? 'dialog' : 'navigation'" [mode]="(isTablet$ | async) ? 'over' : 'side'" [opened]="(isTablet$ | async) === false" libSidebar>
    <mat-toolbar [class.inactive]="!operatorAccountInfo.active" class="logo" routerLink="/">
      <a routerLink="/">
        <img class="mr-10" src="assets/img/gold-logo.svg" width="50" height="45" alt="">
        <img src="assets/img/opes-jet.svg" width="160" height="45" alt="">
      </a>
    </mat-toolbar>
    <mat-nav-list class="fg-1 ofy-auto">
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/frps" routerLinkActive="active">
        <div class="row jc-sb ai-c fg-1">
          <span class="row ai-c">
            <span class="default-label">All Requests</span>
          </span>
          <div *ngIf="unviewedFrpsLength$ | async as unviewedFrpsLength" class="row ai-c">
            <span class="row jc-c ai-c item-counter ml-10">{{unviewedFrpsLength}}</span>
          </div>
        </div>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/offers" routerLinkActive="active">
        <div class="row jc-sb ai-c fg-1">
          <span class="row ai-c">
            <span class="default-label">Bidding</span>
          </span>
          <div class="row ai-c">
            <!-- <span *ngIf="totalUnread.offers" [matBadge]="totalUnread.offers" matBadgePosition="after" matBadgeSize="small">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 3C2.51367 3 1.5 4.01367 1.5 5.25V12.75C1.5 13.9863 2.51367 15 3.75 15H5.98828V18L9.99609 15H14.25C15.4863 15 16.5 13.9863 16.5 12.75V5.25C16.5 4.01367 15.4863 3 14.25 3H3.75ZM3.75 4.5H14.25C14.6719 4.5 15 4.82812 15 5.25V12.75C15 13.1719 14.6719 13.5 14.25 13.5H9.49219L7.48828 15V13.5H3.75C3.32812 13.5 3 13.1719 3 12.75V5.25C3 4.82812 3.32812 4.5 3.75 4.5ZM18 7.5V12.75C18 14.8184 16.3184 16.5 14.25 16.5H12.2812L12.2754 16.5059C11.5254 16.5059 10.8691 16.5059 10.4824 16.5059L7.86328 18.4688C8.26758 19.0898 8.95898 19.5 9.75 19.5H14.0039L18.0059 22.5V19.5H20.25C21.4922 19.5 22.5 18.4922 22.5 17.25V9.75C22.5 8.50781 21.4922 7.5 20.25 7.5H18Z" fill="white"/>
              </svg>
            </span> -->
          </div>
        </div>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/bookings" routerLinkActive="active">
        <div class="row jc-sb ai-c fg-1">
          <span class="row ai-c">
            <span class="default-label">Ongoing Bookings</span>
          </span>
          <div class="row ai-c">
            <!-- <span *ngIf="totalUnread.bookings" [matBadge]="totalUnread.bookings" matBadgePosition="after" matBadgeSize="small">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 3C2.51367 3 1.5 4.01367 1.5 5.25V12.75C1.5 13.9863 2.51367 15 3.75 15H5.98828V18L9.99609 15H14.25C15.4863 15 16.5 13.9863 16.5 12.75V5.25C16.5 4.01367 15.4863 3 14.25 3H3.75ZM3.75 4.5H14.25C14.6719 4.5 15 4.82812 15 5.25V12.75C15 13.1719 14.6719 13.5 14.25 13.5H9.49219L7.48828 15V13.5H3.75C3.32812 13.5 3 13.1719 3 12.75V5.25C3 4.82812 3.32812 4.5 3.75 4.5ZM18 7.5V12.75C18 14.8184 16.3184 16.5 14.25 16.5H12.2812L12.2754 16.5059C11.5254 16.5059 10.8691 16.5059 10.4824 16.5059L7.86328 18.4688C8.26758 19.0898 8.95898 19.5 9.75 19.5H14.0039L18.0059 22.5V19.5H20.25C21.4922 19.5 22.5 18.4922 22.5 17.25V9.75C22.5 8.50781 21.4922 7.5 20.25 7.5H18Z" fill="white"/>
              </svg>
            </span> -->
            <span *ngIf="bookings | activeBookings" class="row jc-c ai-c item-counter ml-10" matTooltipShowDelay="500" matTooltip="You have {{bookings | activeBookings}} bookings that need your action">{{bookings | activeBookings}}</span>
          </div>
        </div>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/archive/bookings" routerLinkActive="active">
        <div class="row jc-sb ai-c fg-1">
          <span class="row ai-c">
            <span class="default-label">Completed</span>
          </span>
        </div>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/featured-flights" routerLinkActive="active">
        <div class="row jc-sb ai-c fg-1">
          <span class="row ai-c">
            <span class="default-label">Empty Legs</span>
          </span>
          <div *ngIf="isWarnFeaturedFlights$ | async" class="row ai-c">
            <span class="row jc-c ai-c">
              <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1284 0.549276C15.5108 -0.130326 16.4892 -0.130326 16.8716 0.549276L20.0685 6.23217C20.2742 6.59774 20.6861 6.79612 21.1001 6.72897L27.5365 5.68522C28.3062 5.5604 28.9162 6.32541 28.6233 7.04804L26.1735 13.0907C26.0159 13.4795 26.1176 13.9252 26.4283 14.2071L31.2573 18.5884C31.8348 19.1124 31.617 20.0663 30.8694 20.2878L24.6176 22.14C24.2154 22.2592 23.9304 22.6167 23.9037 23.0353L23.4891 29.5425C23.4395 30.3206 22.5579 30.7452 21.9186 30.2988L16.5725 26.5658C16.2286 26.3256 15.7714 26.3256 15.4275 26.5658L10.0814 30.2988C9.4421 30.7452 8.56052 30.3206 8.51094 29.5425L8.0963 23.0353C8.06963 22.6167 7.78456 22.2592 7.38239 22.14L1.1306 20.2878C0.382961 20.0663 0.165229 19.1124 0.742719 18.5884L5.57174 14.2071C5.88238 13.9252 5.98412 13.4795 5.82653 13.0907L3.37673 7.04804C3.08377 6.32541 3.69384 5.5604 4.46354 5.68522L10.8999 6.72897C11.3139 6.79612 11.7258 6.59774 11.9315 6.23217L15.1284 0.549276Z" fill="white" />
                <text x="14" y="21" fill="#0B1129">!</text>
              </svg>
            </span>
          </div>
        </div>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/aircrafts-saved" routerLinkActive="active">
        <span class="default-label">My Fleet</span>
      </a>
<!--       <div class="pl-16 lh-36 fs-16 mt-20 archive-label">Archive</div>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/archive/requests" routerLinkActive="active">
        <span class="link-label">Requests</span>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/archive/offers" routerLinkActive="active">
        <span class="link-label">Offers</span>
      </a>
      <a [class.inactive]="!operatorAccountInfo.active" mat-list-item routerLink="/archive/bookings" routerLinkActive="active">
        <span class="link-label">Confirmed Flights</span>
      </a> -->
    </mat-nav-list>
    <div>
      <a routerLink="/account" routerLinkActive="active" class="account">
        <div *ngIf="operatorAccountInfo.logo" class="avatar" [class.offline]="!isConnectionAvailable">
          <img [src]="userService.baseUrl+'/operators/'+operatorAccountInfo.id+'/logo?'+operatorAccountInfo.logo" alt="">
          <span class="avatar__status"></span>
        </div>
        <div class="col name" [class.has-logo]="operatorAccountInfo.logo">
          <span class="text-wrap tt-u fw-500">{{operatorAccountInfo.name}}</span>
          <span class="rating" *ngIf="operatorAccountInfo.status!=='gray'" [class.gold]="operatorAccountInfo.status==='gold'" [class.silver]="operatorAccountInfo.status==='silver'" [class.bronze]="operatorAccountInfo.status==='bronze'">{{operatorAccountInfo.status}}</span>
        </div>
      </a>
      <div class="contact-us" (click)="userService.contactUs()">
        <span>Contact Us</span>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<!-- <a mat-list-item routerLink="/lost-bids" routerLinkActive="active">Lost BIDs</a> -->
<!-- <span class="logo" routerLink="/"></span> -->
<!-- <p>{{ curEmail }}</p> -->
<!-- <span matBadge="{{ messagesCount }}" matBadgeOverlap="true"><a mat-button href="" routerLink="/messages">Messages</a></span> -->
<!-- <a mat-button routerLink="/signin" *ngIf="afAuth.user | async as user" > <img class="avatar" [src]="user.photoURL || 'assets/default-user.svg'"> Profile </a>&ndash;&gt; -->
