import { NgModule } from '@angular/core';
import { PeriodDashboardPipe } from './pipes/period-dashboard.pipe';
import { SearchDashboardPipe } from './pipes/search-dashboard.pipe';
import { SearchFrpsPipe } from './pipes/search-frps.pipe';
import { PeriodFrpsPipe } from './pipes/period-frps.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent } from './modals/cropper/cropper.component';
import { GlobalSharedModule } from 'common';
import { TopbarComponent } from './topbar/topbar.component';
import { MaxpassFilterComponent } from './modals/maxpass-filter/maxpass-filter.component';
import { MaxpassDashboardPipe } from './pipes/maxpass-dashboard.pipe';
import { MaxpassFrpsPipe } from './pipes/maxpass-frps.pipe';

const components = [
  MaxpassDashboardPipe,
  MaxpassFrpsPipe,
  PeriodDashboardPipe,
  SearchDashboardPipe,
  SearchFrpsPipe,
  PeriodFrpsPipe,
  CropperComponent,
  MaxpassFilterComponent,
  TopbarComponent
];

const modules = [
  ImageCropperModule,
  GlobalSharedModule
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [
    ...components,
    ...modules,
  ]
})
export class SharedModule { }
