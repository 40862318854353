import { Pipe, PipeTransform } from '@angular/core';
import { AirportDatetimeService, Booking, Offer } from 'common';
import { DateTime } from 'luxon';

@Pipe({
  name: 'periodDashboard'
})
export class PeriodDashboardPipe implements PipeTransform {
  constructor(
    private adt: AirportDatetimeService
  ) {}
  transform(items: Array<Booking | Offer>, start?: Date, end?: Date): any {
    if (!items) return [];
    let arr = items.slice();
    if (start) {
      const formatStart = DateTime.fromJSDate(start).toISODate();
      arr = arr.filter(item => {
        const legOrBid = ('bookingLegs' in item) ? item.bookingLegs[ 0 ] : item.bids[ 0 ];
        const date = this.adt.getAirportDate(legOrBid.takeoffDate, legOrBid.from);
        return date >= formatStart;
      });
    }
    if (end) {
      const formatEnd = DateTime.fromJSDate(end).toISODate();
      arr = arr.filter(item => {
        const legOrBid = ('bookingLegs' in item) ? item.bookingLegs[ 0 ] : item.bids[ 0 ];
        const date = this.adt.getAirportDate(legOrBid.takeoffDate, legOrBid.from);
        return date <= formatEnd;
      });
    }
    return arr;
  }
}
