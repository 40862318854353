import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import {
  AircraftsSavedService,
  BidsService, Booking, BookingsService, ChatService,
  FeaturedFlightsService, FrpsService, generateRandom, Offer, OperatorAccount, PlatformService, SnackService, StatusService, TimeService, User, UserService
} from 'common';
import { combineLatest, debounceTime, delay, Observable, Subject } from 'rxjs';
import { first, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { TopbarService } from '../../shared/topbar/topbar.service';
import { WelcomeComponent } from '../../user/onboarding/welcome/welcome.component';


@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') public sidenav!: MatSidenav;
  expArchive!: boolean;
  bookings?: Booking[];
  offers?: Offer[];
  userInfo!: User;
  operatorAccountInfo!: OperatorAccount;
  totalUnread!: { [x: string]: number; };
  isTablet$: Observable<boolean>;
  isWarnFeaturedFlights$ = new Subject<boolean>;
  events = {
    bidAdded: { event: 'bid_added', message: 'There are changes in My Offers' },
    bidUpdated: { event: 'bid_updated', message: 'There are changes in My Offers' },
    bidDeleted: { event: 'bid_deleted', message: 'There are changes in My Offers' },
    bookingAdded: { event: 'booking_added', message: 'There are changes in My Bookings' },
    bookingUpdated: { event: 'booking_updated', message: 'There are changes in My Bookings' },
    bookingDeleted: { event: 'booking_deleted', message: 'There are changes in My Bookings' },
    frpAdded: { event: 'frp_added', message: 'There are changes in Client Requests' },
    frpUpdated: { event: 'frp_updated', message: 'There are changes in Client Requests' },
    frpDeleted: { event: 'frp_deleted', message: 'There are changes in Client Requests' },
  };
  unsubscribe$ = new Subject<void>;
  isConnectionAvailable: boolean = navigator.onLine;
  @HostListener('window:online', ['$event'])
  online(): void {
    this.isConnectionAvailable = true;
  }
  @HostListener('window:offline', ['$event'])
  offline(): void {
    this.isConnectionAvailable = false;
  }

  constructor(
    private fbRTB: AngularFireDatabase,
    private analytics: AngularFireAnalytics,
    public router: Router,
    public userService: UserService,
    public timeService: TimeService,
    private topbarService: TopbarService,
    private frpsService: FrpsService,
    private bookingsService: BookingsService,
    private featuredFlightsService: FeaturedFlightsService,
    private bidsService: BidsService,
    private aircraftsSavedService: AircraftsSavedService,
    private statusCheckSrv: StatusService,
    private dialog: MatDialog,
    private chatService: ChatService,
    private changeDetection: ChangeDetectorRef,
    private platformService: PlatformService
    ) {
      this.isTablet$ = this.platformService.isTablet$;
      const expArchive = sessionStorage.getItem('expArchive');
      if (expArchive) this.expArchive = JSON.parse(expArchive);
    }

    ngOnInit(): void {
      this.sidenavEvent();
      this.subscibeTotalUnread();
      this.initUser();
      this.checkStatus();
      this.initFRPs();
      this.initBookings();
      this.initOffers();
      this.getMyFeaturedFlights();
      this.getRegions();
      this.getAircrafts();
    }

    ngAfterViewInit(): void {
      this.topbarService.setSidenav(this.sidenav);
    }

    ngOnDestroy(): void {
      this.unsubscribeFromChats();

      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }

  unviewedFrpsLength$ = this.frpsService.frps$.pipe(
    switchMap(frps => this.fbRTB.object<{ [x: string]: string; }>(`userPreferences/${this.userService.userInfo$.getValue().firebaseId}/viewedFrps`).valueChanges().pipe(
      map(viewed => {
        if (!viewed) return frps?.length;
        const arr = frps?.filter(frp => {
          return !viewed[frp.id] || (frp.updated && viewed[frp.id] < frp.updated);
        });
        return arr?.length || 0;
      }))
    ));

    sidenavEvent(): void {
      combineLatest([this.router.events, this.platformService.isTablet$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([event, isMobile]) => {
        if (event instanceof NavigationEnd && isMobile) this.sidenav.close();
      });
  }

  checkStatus(): void {
    const user = this.userService.userInfo$.getValue();
    if (user.operatorAccountOwner || user.operatorAccountAdmin) {
      this.statusCheckSrv.checkStatus()
        .pipe(
          takeUntil(this.unsubscribe$),
          skip(1),
          debounceTime(5000),
          delay(generateRandom()),
        )
        .subscribe(
          data => {
            if (data && data.authorId !== this.userService.userInfo$.getValue().firebaseId) {
              switch (data.type) {
                case this.events.frpAdded.event:
                case this.events.frpUpdated.event:
                case this.events.frpDeleted.event:
                  this.getFRPsSilent();
                  break;
                case this.events.bidAdded.event:
                case this.events.bidUpdated.event:
                case this.events.bidDeleted.event:
                  this.getFRPsSilent();
                  this.getOffersSilent();
                  break;
                default:
                  console.log(`Unknown event ${data.type}.`);
              }
            }
          },
          (err) => {
            console.log('Check status error. ', err);
          }
        );
      this.statusCheckSrv.checkBookings(this.userService.userInfo$.getValue().firebaseId)
        .pipe(
          takeUntil(this.unsubscribe$),
          skip(1),
          debounceTime(5000),
          delay(generateRandom()),
        )
        .subscribe(
          data => {
            if (data && data.authorId !== this.userService.userInfo$.getValue().firebaseId) {
              this.getFRPsSilent();
              this.getOffersSilent();
              this.getBookingsSilent();
            }
          },
          (err) => {
            console.log('Check status error. ', err);
          }
        );
      this.statusCheckSrv.checkFeaturedFlights(this.userService.userInfo$.getValue().firebaseId)
        .pipe(
          takeUntil(this.unsubscribe$),
          skip(1),
          debounceTime(1000),
          delay(generateRandom()),
        )
        .subscribe(
          data => {
            if (data && data.authorId !== this.userService.userInfo$.getValue().firebaseId) {
              this.getMyFeaturedFlights(true);
            }
          }
        );
    }
  }

  getRegions(): void {
    this.userService.getRegions()
      .pipe(first())
      .subscribe(regions => this.userService.regions$.next(regions));
  }

  getAircrafts(): void {
    this.aircraftsSavedService.getAircraftsSaved().pipe(first()).subscribe();
  }

  subscibeTotalUnread(): void {
    this.chatService.totalUnread$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.totalUnread = data;
        this.changeDetection.detectChanges();
      });
  }

  unsubscribeFromChats(): void {
    Object.keys(this.chatService.chatsRefs).forEach(key => {
      this.chatService.chatsRefs[key].forEach(chatRef => {
        this.chatService.unSubscribeUnreadMessages(chatRef);
      });
    });
  }

  initFRPs(): void {
    this.frpsService.getFRPs({ region: true, relevant: true }).pipe(first()).subscribe();
  }

  getFRPsSilent(): void {
    this.frpsService.getFRPs({ silent: true, region: true, relevant: true }).pipe(first()).subscribe();
  }

  initBookings(): void {
    this.bookingsService.bookings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(bookings => {
        this.bookings = bookings;
        this.chatService.chatsRefs.bookings.forEach(chatRef => {
          this.chatService.unSubscribeUnreadMessages(chatRef);
        });
        this.chatService.chats.bookings = {};
        bookings?.forEach(booking => {
          if (booking.bids[0]) this.chatService.chatsRefs.bookings.push(this.chatService.subscribeUnreadMessages(booking.bids[0].offer, this.userInfo.firebaseId));
        });
      });
    this.bookingsService.getBookings().pipe(first()).subscribe();
  }

  getBookingsSilent(): void {
    this.bookingsService.getBookings({ silent: true }).pipe(first()).subscribe();
  }

  initOffers(): void {
    this.bidsService.offers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(offers => {
        this.offers = offers;
        this.chatService.chatsRefs.offers.forEach(chatRef => {
          this.chatService.unSubscribeUnreadMessages(chatRef);
        });
        this.chatService.chats.offers = {};
        offers.forEach(offer => {
          this.chatService.chatsRefs.offers.push(this.chatService.subscribeUnreadMessages(offer.offer, this.userInfo.firebaseId));
        });
      });
    this.bidsService.getMyBids().pipe(first()).subscribe();
  }

  getOffersSilent(): void {
    this.bidsService.getMyBids({ silent: true }).pipe(first()).subscribe();
  }

  getMyFeaturedFlights(silent = false): void {
    this.featuredFlightsService.getMyFeaturedFlights({ silent })
    .pipe(first())
    .subscribe(resp => {
      this.isWarnFeaturedFlights$.next(resp.some(flight => flight.status === 'booked_paid' && flight.bookings[0]));
    });
  }

  initUser(): void {
    this.userService.userInfo$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      this.userInfo = user;
      this.operatorAccountInfo = user.operatorAccountOwner || user.operatorAccountAdmin;
      if (user.operatorAccountOwner?.active || user.operatorAccountAdmin?.active) {
        const users = JSON.parse(localStorage.getItem('opesJet_users_welcome') || '{}');
        if (!users[ user.firebaseId ]) {
          users[ user.firebaseId ] = true;
          this.dialog.open(WelcomeComponent, {
            width: '486px',
            data: {
              active: true,
            },
            panelClass: 'no-padding-dialog',
            backdropClass: 'bdrop',
            autoFocus: false
          });
          localStorage.setItem('opesJet_users_welcome', JSON.stringify(users));
          this.analytics.logEvent('12_welcome_to_the_panel_screen', {});
        }
      }
      this.chatService.subscribeCreatedChat(this.userInfo.firebaseId).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe();
    });
  }

  expandArchive(opened?: boolean): void {
    if (opened) sessionStorage.setItem('expArchive', 'true');
    else sessionStorage.removeItem('expArchive');
  }

}
