import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionCheckService {

  constructor(
    private http: HttpClient,
  ) { }

  checkAPI(): Observable<unknown> {
    return this.http.get<unknown>(environment.apiHealthCheckUrl);
  }

}
