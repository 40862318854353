import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionAlertComponent } from 'common';

@Component({
  selector: 'app-maxpass-filter',
  templateUrl: './maxpass-filter.component.html',
  styleUrls: ['./maxpass-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxpassFilterComponent {
  modalForm = this.fb.group({
    numPass: [undefined, Validators.min(0)]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      numPass: number,
    },
    private fb: UntypedFormBuilder,
  ) {
    this.modalForm.patchValue(data);
  }

}
