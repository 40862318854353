import { Pipe, PipeTransform } from '@angular/core';
import { Booking, BookingStatusPipe } from 'common';
@Pipe({
  name: 'activeBookings'
})
export class ActiveBookingsPipe implements PipeTransform {
  constructor(private bookingStatus: BookingStatusPipe) {
  }
  transform(bookings?: Booking[]): number {
    return bookings ? bookings.filter(b => this.bookingStatus.transform(b).buttonText.length).length : 0;
  }

}
