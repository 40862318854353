import { Pipe, PipeTransform } from '@angular/core';
import { AirportDatetimeService, FRP } from 'common';
import { DateTime } from 'luxon';

@Pipe({
  name: 'periodFrps'
})
export class PeriodFrpsPipe implements PipeTransform {
  constructor(
    private adt: AirportDatetimeService
  ) {}
  transform(items: FRP[], start?: Date, end?: Date): FRP[] {
    if (!items) return [];
    let arr = items.slice();
    if (start) {
      const formatStart = DateTime.fromJSDate(start).toISODate();
      arr = arr.filter(item => {
        const date = this.adt.getAirportDate(item.legs[ 0 ].flightDate, item.legs[ 0 ].from);
        return date >= formatStart;
      });
    }
    if (end) {
      const formatEnd = DateTime.fromJSDate(end).toISODate();
      arr = arr.filter(item => {
        const date = this.adt.getAirportDate(item.legs[ 0 ].flightDate, item.legs[ 0 ].from);
        return date <= formatEnd;
      });
    }
    return arr;
  }
}
