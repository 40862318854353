<div class="row jc-c ai-c modal-top px-30 py-20">
	<img src="assets/img/dark-logo.svg" alt="">
</div>
<div class="modal-container">
	<div  class="modal-container__text">
		<ng-container *ngIf="data?.active">
			Welcome to the OPES JET Operator Panel! Here you can receive flight requests, send flight offers, see competing flight offers, manage your confirmed flights, and chat with the lead passengers directly.
		</ng-container>
		<ng-container *ngIf="!data?.active">
			<div class="fw-b">Thank you!</div>
			<div>We will now check the data you've entered. Once complete, we will fully release your account.</div>
			<div>Please bear with us, as this process may take a few days to complete.</div>
		</ng-container>
	</div>
</div>
<div class="row jc-c ai-c modal-bottom">
	<button mat-raised-button color="primary" mat-dialog-close class="w-128">OK</button>
</div>
