import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: [ './cropper.component.scss' ]
})
export class CropperComponent {
  imageChangedEvent = '';
  croppedImage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { file: File },
    public dialogRef: MatDialogRef<CropperComponent>,
  ) { }

  save(): void {
    this.dialogRef.close(base64ToFile(this.croppedImage));
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64 || '';
  }
  imageLoaded(): void {
    // show cropper
  }
  cropperReady(): void {
    // cropper ready
  }
  loadImageFailed(): void {
    // show message
  }

}
