import { Pipe, PipeTransform } from '@angular/core';
import { Offer, Booking } from 'common';

@Pipe({
  name: 'maxpassDashboard'
})
export class MaxpassDashboardPipe implements PipeTransform {

  transform(arr: Array<Offer|Booking>, numPass: number): any {
    if (!numPass) return arr;
    return arr.filter(el => {
      const a = ((el as Booking).bookingLegs || el.bids).map(leg => (leg.leg || leg).adultsNumber+(leg.leg || leg).childrenNumber).reduce((sum, num) => {
        return sum + num;
      });
      return a <= numPass ? el : false;
    });
  }

}
