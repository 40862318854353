<div class="modal-header">
	<span class="modal-header__text">Max Passenger</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form class="modal-container" [formGroup]="modalForm">
	<div class="modal-container__text">Maximum number of passengers</div>
	<mat-form-field class="comment" appearance="outline">
		<mat-label>Num of pass</mat-label>
		<input type="number" matInput placeholder="Num of pass" formControlName="numPass"/>
	</mat-form-field>
</form>
<div class="modal-bottom">
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-stroked-button mat-dialog-close>No</button>
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10" [disabled]="modalForm.invalid" [mat-dialog-close]="modalForm.value.numPass">Yes</button>
</div>
