import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperatorComponent } from './operator/toolbar/operator.component';
import { MaintenanceOn, MaintenanceOff, Verified } from './user/auth.guard';
import { MaintenanceBannerComponent } from './shared/components/maintenance-banner/maintenance-banner.component';

const routes: Routes = [
{
  path: '',
  canActivate: [ MaintenanceOn ],
  loadChildren: () => import('./user/onboarding/user.module').then(m => m.UserModule)
},
{
  path: '',
  component: OperatorComponent,
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule),
},
{
  path: 'make-offer',
  data: { title: 'Make Offer' },
  canActivate: [ MaintenanceOn ],
  loadChildren: () => import('common').then(m => m.MakeOfferModule),
},
{
  path: 'edit-offer',
  data: { title: 'Edit Offer' },
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('common').then(m => m.MakeOfferModule),
},
{
  path: 'edit-booking',
  data: { title: 'Edit Confirmed Flight' },
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('common').then(m => m.MakeOfferModule),
},
{
  path: 'flight-details',
  data: { title: 'Flight details' },
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('common').then(m => m.FlightDetailsModule),
},
{
  path: 'featured-flight-confirmation',
  data: { title: 'Empty Leg Confirmation' },
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('common').then(m => m.FeaturedFlightConfirmationModule),
},
{
  path: 'make-featured-deal',
  data: { title: 'Empty Leg Creation' },
  canActivate: [ MaintenanceOn, Verified ],
  loadChildren: () => import('common').then(m => m.MakeFeaturedDealModule),
},
{
  path: 'maintenance',
  data: { title: 'Maintenance' },
  component: MaintenanceBannerComponent,
  canActivate: [ MaintenanceOff ],
},
{
  path: '**', redirectTo: 'bookings', pathMatch: 'full'
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
