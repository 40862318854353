<mat-toolbar *ngIf="alwaysShow || (isTablet$ | async) === true" class="content-header">
    <button mat-icon-button type="button" class="menu" aria-label="Toggle sidenav"  (click)="clickLeftIcon()">
        <div class="hamburger">
          <span class="hamburger__top" [class.active]="backState"></span>
          <span class="hamburger__center"></span>
          <span class="hamburger__bottom" [class.active]="backState"></span>
        </div>
    </button>
    <span class="content-header__title">{{ curTitle$ | async }}</span>
    <ng-content select="[rightButton]"></ng-content>
</mat-toolbar>
