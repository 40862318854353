import { Pipe, PipeTransform } from '@angular/core';
import { FRP } from 'common';

@Pipe({
  name: 'maxpassFrps'
})
export class MaxpassFrpsPipe implements PipeTransform {

  transform(frps: FRP[], numPass: number): FRP[] {
    if (!numPass) return frps;
    return frps.filter(frp => {
      const a = frp.legs.map(leg => leg.adultsNumber+leg.childrenNumber).reduce((sum, num) => {
        return sum + num;
      });
      return a <= numPass ? frp : false;
    });
  }

}
