import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformService } from 'common';
import { Observable } from 'rxjs';
import { TopbarService } from './topbar.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  @Output() backClick = new EventEmitter();
  @Input() backState?: boolean | string;
  @Input() alwaysShow?: boolean;
  curTitle$: Observable<string> = this.topbarService.getTitleObject();
  isTablet$: Observable<boolean>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private topbarService: TopbarService,
    private platformService: PlatformService,
  ) {
    this.isTablet$ = this.platformService.isTablet$;
  }

  clickLeftIcon(): void {
    if (this.backState) {
      if (this.backClick) {
        this.backClick.emit();
      } else {
        this.router.navigate([`/${window.location.pathname}`], { relativeTo: this.route });
      }
    } else {
      this.topbarService.open();
    }
  }
}
