import { Pipe, PipeTransform } from '@angular/core';
import { FRP } from 'common';


@Pipe({
  name: 'searchFrps'
})
export class SearchFrpsPipe implements PipeTransform {

  transform(frps: FRP[], search: string, filters?: any): FRP[] {
    let newArr = frps;
    if (search || search.length >= 3) {
      const word = search.toLowerCase();
      newArr = newArr.filter(frp => {
        if (frp.id.toLowerCase().includes(word)) return frp;
        for (const leg of frp.legs) {
          if (leg.from?.city?.toLowerCase().includes(word)) return frp;
          if (leg.from?.countryName?.toLowerCase().includes(word)) return frp;
          if (leg.from?.icao?.toLowerCase().includes(word)) return frp;
          if (leg.from?.iata?.toLowerCase().includes(word)) return frp;
          if (leg.from?.name?.toLowerCase().includes(word)) return frp;
          if (leg.to?.city?.toLowerCase().includes(word)) return frp;
          if (leg.to?.countryName?.toLowerCase().includes(word)) return frp;
          if (leg.to?.icao?.toLowerCase().includes(word)) return frp;
          if (leg.to?.iata?.toLowerCase().includes(word)) return frp;
          if (leg.to?.name?.toLowerCase().includes(word)) return frp;
        }
        return false;
      });
    }
    if (filters) {
      if (filters.sortBy === 'Flight date') {
        newArr = newArr.sort((a, b) => {
          if (filters.sortDirection === 'asc') {
            return a.legs[0].flightDate > b.legs[0].flightDate ? 1 : -1;
          } else return b.legs[0].flightDate > a.legs[0].flightDate ? 1 : -1;
        });
      } else {
        newArr = newArr.sort((a, b) => {
          if (filters.sortDirection === 'asc') {
            return a.created > b.created ? 1 : -1;
          } else return b.created > a.created ? 1 : -1;
        });
      }

      if(filters.locationToggle) {
        switch (filters.locationKey) {
          case 'regions':
            newArr = newArr.filter(frp => {
              return frp.legs.some(leg => {
                return filters.locationValue.some((val: any) => leg.from.tzDbTimeZone.split('/')[0] === val) ||
                filters.locationValue.some((val: any) => leg.to.tzDbTimeZone.split('/')[0] === val);
              });
            });
            break;
          case 'countries':
            newArr = newArr.filter(frp => {
              return frp.legs.some(leg => {
                return filters.locationValue.some((val: any) => leg.from.countryName === val) ||
                filters.locationValue.some((val: any) => leg.to.countryName === val);
              });
            });
            break;
          case 'cities':
            newArr = newArr.filter(frp => {
              return frp.legs.some(leg => {
                return filters.locationValue.some((val: any) => leg.from.city === val) ||
                filters.locationValue.some((val: any) => leg.to.city === val);
              });
            });
            break;
          case 'airports':
            newArr = newArr.filter(frp => {
              return frp.legs.some(leg => {
                return filters.locationValue.some((val: any) => leg.from.name === val) ||
                filters.locationValue.some((val: any) => leg.to.name === val);
              });
            });
            break;
          default:
            break;
        }
      }
    }
    return newArr;
  }
}
